var exports = {};
exports = {
  A: {
    A: {
      K: 0,
      E: 0,
      F: 0.046943,
      G: 0.0625907,
      A: 0,
      B: 0.500725,
      bC: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "bC", "K", "E", "F", "G", "A", "B", "", "", ""],
    E: "IE",
    F: {
      bC: 962323200,
      K: 998870400,
      E: 1161129600,
      F: 1237420800,
      G: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      "1": 0.007222,
      "2": 0.014444,
      "3": 0.010833,
      "4": 0.007222,
      "5": 0.007222,
      "6": 0.010833,
      "7": 0.007222,
      "8": 0.014444,
      "9": 0.03611,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      O: 0.003611,
      P: 0.043332,
      Q: 0,
      I: 0,
      R: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0.014444,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0.007222,
      r: 0.007222,
      s: 0.064998,
      t: 0.007222,
      u: 0.007222,
      AB: 0.021666,
      BB: 0.032499,
      CB: 0.021666,
      DB: 0.039721,
      EB: 0.122774,
      FB: 3.94682,
      D: 0.512762
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "L", "M", "H", "N", "O", "P", "Q", "I", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "D", "", "", ""],
    E: "Edge",
    F: {
      "1": 1680825600,
      "2": 1683158400,
      "3": 1685664000,
      "4": 1689897600,
      "5": 1692576000,
      "6": 1694649600,
      "7": 1697155200,
      "8": 1698969600,
      "9": 1701993600,
      C: 1438128000,
      L: 1447286400,
      M: 1470096000,
      H: 1491868800,
      N: 1508198400,
      O: 1525046400,
      P: 1542067200,
      Q: 1579046400,
      I: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      j: 1649635200,
      k: 1651190400,
      l: 1653955200,
      m: 1655942400,
      n: 1659657600,
      o: 1661990400,
      p: 1664755200,
      q: 1666915200,
      r: 1670198400,
      s: 1673481600,
      t: 1675900800,
      u: 1678665600,
      AB: 1706227200,
      BB: 1708732800,
      CB: 1711152000,
      DB: 1713398400,
      EB: 1715990400,
      FB: 1718841600,
      D: 1721865600
    },
    D: {
      C: "ms",
      L: "ms",
      M: "ms",
      H: "ms",
      N: "ms",
      O: "ms",
      P: "ms"
    }
  },
  C: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0.007222,
      "3": 0,
      "4": 0.346656,
      "5": 0,
      "6": 0.007222,
      "7": 0.07222,
      "8": 0,
      "9": 0.007222,
      cC: 0,
      DC: 0,
      J: 0,
      GB: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0.018055,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      O: 0,
      P: 0,
      HB: 0,
      v: 0,
      w: 0,
      x: 0,
      y: 0,
      z: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0.007222,
      aB: 0.007222,
      bB: 0.007222,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0.003611,
      hB: 0,
      iB: 0.043332,
      jB: 0.003611,
      kB: 0.007222,
      lB: 0,
      mB: 0.018055,
      nB: 0,
      oB: 0,
      EC: 0.003611,
      pB: 0,
      FC: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0.003611,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0.014444,
      Q: 0,
      I: 0,
      R: 0,
      GC: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0.007222,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0.003611,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.007222,
      m: 0.007222,
      n: 0,
      o: 0.003611,
      p: 0,
      q: 0.003611,
      r: 0.003611,
      s: 0.010833,
      t: 0,
      u: 0,
      AB: 0.010833,
      BB: 0.007222,
      CB: 0.028888,
      DB: 0.010833,
      EB: 0.018055,
      FB: 0.046943,
      D: 0.592204,
      HC: 0.953304,
      IC: 0.007222,
      JC: 0,
      dC: 0,
      eC: 0,
      fC: 0,
      gC: 0
    },
    B: "moz",
    C: ["cC", "DC", "fC", "gC", "J", "GB", "K", "E", "F", "G", "A", "B", "C", "L", "M", "H", "N", "O", "P", "HB", "v", "w", "x", "y", "z", "0", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "EC", "pB", "FC", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "Q", "I", "R", "GC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "D", "HC", "IC", "JC", "dC", "eC"],
    E: "Firefox",
    F: {
      "0": 1379376000,
      "1": 1681171200,
      "2": 1683590400,
      "3": 1686009600,
      "4": 1688428800,
      "5": 1690848000,
      "6": 1693267200,
      "7": 1695686400,
      "8": 1698105600,
      "9": 1700524800,
      cC: 1161648000,
      DC: 1213660800,
      fC: 1246320000,
      gC: 1264032000,
      J: 1300752000,
      GB: 1308614400,
      K: 1313452800,
      E: 1317081600,
      F: 1317081600,
      G: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      L: 1335225600,
      M: 1338854400,
      H: 1342483200,
      N: 1346112000,
      O: 1349740800,
      P: 1353628800,
      HB: 1357603200,
      v: 1361232000,
      w: 1364860800,
      x: 1368489600,
      y: 1372118400,
      z: 1375747200,
      IB: 1386633600,
      JB: 1391472000,
      KB: 1395100800,
      LB: 1398729600,
      MB: 1402358400,
      NB: 1405987200,
      OB: 1409616000,
      PB: 1413244800,
      QB: 1417392000,
      RB: 1421107200,
      SB: 1424736000,
      TB: 1428278400,
      UB: 1431475200,
      VB: 1435881600,
      WB: 1439251200,
      XB: 1442880000,
      YB: 1446508800,
      ZB: 1450137600,
      aB: 1453852800,
      bB: 1457395200,
      cB: 1461628800,
      dB: 1465257600,
      eB: 1470096000,
      fB: 1474329600,
      gB: 1479168000,
      hB: 1485216000,
      iB: 1488844800,
      jB: 1492560000,
      kB: 1497312000,
      lB: 1502150400,
      mB: 1506556800,
      nB: 1510617600,
      oB: 1516665600,
      EC: 1520985600,
      pB: 1525824000,
      FC: 1529971200,
      qB: 1536105600,
      rB: 1540252800,
      sB: 1544486400,
      tB: 1548720000,
      uB: 1552953600,
      vB: 1558396800,
      wB: 1562630400,
      xB: 1567468800,
      yB: 1571788800,
      zB: 1575331200,
      "0B": 1578355200,
      "1B": 1581379200,
      "2B": 1583798400,
      "3B": 1586304000,
      "4B": 1588636800,
      "5B": 1591056000,
      "6B": 1593475200,
      Q: 1595894400,
      I: 1598313600,
      R: 1600732800,
      GC: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      j: 1651536000,
      k: 1653955200,
      l: 1656374400,
      m: 1658793600,
      n: 1661212800,
      o: 1663632000,
      p: 1666051200,
      q: 1668470400,
      r: 1670889600,
      s: 1673913600,
      t: 1676332800,
      u: 1678752000,
      AB: 1702944000,
      BB: 1705968000,
      CB: 1708387200,
      DB: 1710806400,
      EB: 1713225600,
      FB: 1715644800,
      D: 1718064000,
      HC: 1720483200,
      IC: 1722902400,
      JC: null,
      dC: null,
      eC: null
    }
  },
  D: {
    A: {
      "0": 0,
      "1": 0.050554,
      "2": 0.093886,
      "3": 0.090275,
      "4": 0.03611,
      "5": 0.158884,
      "6": 0.104719,
      "7": 0.075831,
      "8": 0.064998,
      "9": 0.111941,
      J: 0,
      GB: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      O: 0,
      P: 0,
      HB: 0,
      v: 0,
      w: 0,
      x: 0,
      y: 0,
      z: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0.010833,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0.003611,
      cB: 0,
      dB: 0.003611,
      eB: 0.021666,
      fB: 0.021666,
      gB: 0.018055,
      hB: 0,
      iB: 0.003611,
      jB: 0.007222,
      kB: 0,
      lB: 0,
      mB: 0.010833,
      nB: 0,
      oB: 0.007222,
      EC: 0,
      pB: 0,
      FC: 0.003611,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0.025277,
      vB: 0.007222,
      wB: 0,
      xB: 0.014444,
      yB: 0.097497,
      zB: 0.003611,
      "0B": 0,
      "1B": 0.014444,
      "2B": 0.010833,
      "3B": 0.010833,
      "4B": 0.007222,
      "5B": 0.010833,
      "6B": 0.018055,
      Q: 0.10833,
      I: 0.014444,
      R: 0.021666,
      S: 0.03611,
      T: 0.007222,
      U: 0.014444,
      V: 0.028888,
      W: 0.075831,
      X: 0.014444,
      Y: 0.010833,
      Z: 0.014444,
      a: 0.050554,
      b: 0.025277,
      c: 0.021666,
      d: 0.050554,
      e: 0.014444,
      f: 0.014444,
      g: 0.021666,
      h: 0.054165,
      i: 0.032499,
      j: 0.021666,
      k: 0.021666,
      l: 0.014444,
      m: 0.126385,
      n: 0.064998,
      o: 0.018055,
      p: 0.032499,
      q: 0.03611,
      r: 0.054165,
      s: 1.46607,
      t: 0.028888,
      u: 0.043332,
      AB: 0.148051,
      BB: 0.184161,
      CB: 0.176939,
      DB: 0.278047,
      EB: 0.769143,
      FB: 14.4007,
      D: 1.68273,
      HC: 0.018055,
      IC: 0,
      JC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "J", "GB", "K", "E", "F", "G", "A", "B", "C", "L", "M", "H", "N", "O", "P", "HB", "v", "w", "x", "y", "z", "0", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "EC", "pB", "FC", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "Q", "I", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "D", "HC", "IC", "JC"],
    E: "Chrome",
    F: {
      "0": 1357862400,
      "1": 1680566400,
      "2": 1682985600,
      "3": 1685404800,
      "4": 1689724800,
      "5": 1692057600,
      "6": 1694476800,
      "7": 1696896000,
      "8": 1698710400,
      "9": 1701993600,
      J: 1264377600,
      GB: 1274745600,
      K: 1283385600,
      E: 1287619200,
      F: 1291248000,
      G: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      L: 1312243200,
      M: 1316131200,
      H: 1316131200,
      N: 1319500800,
      O: 1323734400,
      P: 1328659200,
      HB: 1332892800,
      v: 1337040000,
      w: 1340668800,
      x: 1343692800,
      y: 1348531200,
      z: 1352246400,
      IB: 1361404800,
      JB: 1364428800,
      KB: 1369094400,
      LB: 1374105600,
      MB: 1376956800,
      NB: 1384214400,
      OB: 1389657600,
      PB: 1392940800,
      QB: 1397001600,
      RB: 1400544000,
      SB: 1405468800,
      TB: 1409011200,
      UB: 1412640000,
      VB: 1416268800,
      WB: 1421798400,
      XB: 1425513600,
      YB: 1429401600,
      ZB: 1432080000,
      aB: 1437523200,
      bB: 1441152000,
      cB: 1444780800,
      dB: 1449014400,
      eB: 1453248000,
      fB: 1456963200,
      gB: 1460592000,
      hB: 1464134400,
      iB: 1469059200,
      jB: 1472601600,
      kB: 1476230400,
      lB: 1480550400,
      mB: 1485302400,
      nB: 1489017600,
      oB: 1492560000,
      EC: 1496707200,
      pB: 1500940800,
      FC: 1504569600,
      qB: 1508198400,
      rB: 1512518400,
      sB: 1516752000,
      tB: 1520294400,
      uB: 1523923200,
      vB: 1527552000,
      wB: 1532390400,
      xB: 1536019200,
      yB: 1539648000,
      zB: 1543968000,
      "0B": 1548720000,
      "1B": 1552348800,
      "2B": 1555977600,
      "3B": 1559606400,
      "4B": 1564444800,
      "5B": 1568073600,
      "6B": 1571702400,
      Q: 1575936000,
      I: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      j: 1648512000,
      k: 1650931200,
      l: 1653350400,
      m: 1655769600,
      n: 1659398400,
      o: 1661817600,
      p: 1664236800,
      q: 1666656000,
      r: 1669680000,
      s: 1673308800,
      t: 1675728000,
      u: 1678147200,
      AB: 1705968000,
      BB: 1708387200,
      CB: 1710806400,
      DB: 1713225600,
      EB: 1715644800,
      FB: 1718064000,
      D: 1721174400,
      HC: null,
      IC: null,
      JC: null
    }
  },
  E: {
    A: {
      J: 0,
      GB: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0.003611,
      A: 0,
      B: 0,
      C: 0,
      L: 0.007222,
      M: 0.032499,
      H: 0.007222,
      hC: 0,
      KC: 0,
      iC: 0,
      jC: 0,
      kC: 0,
      lC: 0,
      LC: 0,
      "7B": 0.007222,
      "8B": 0.010833,
      mC: 0.061387,
      nC: 0.086664,
      oC: 0.025277,
      MC: 0.010833,
      NC: 0.021666,
      "9B": 0.032499,
      pC: 0.227493,
      AC: 0.028888,
      OC: 0.043332,
      PC: 0.03611,
      QC: 0.10833,
      RC: 0.025277,
      SC: 0.043332,
      qC: 0.299713,
      BC: 0.021666,
      TC: 0.043332,
      UC: 0.043332,
      VC: 0.050554,
      WC: 0.133607,
      XC: 1.76578,
      YC: 0.010833,
      CC: 0.010833,
      rC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "hC", "KC", "J", "GB", "iC", "K", "jC", "E", "kC", "F", "G", "lC", "A", "LC", "B", "7B", "C", "8B", "L", "mC", "M", "nC", "H", "oC", "MC", "NC", "9B", "pC", "AC", "OC", "PC", "QC", "RC", "SC", "qC", "BC", "TC", "UC", "VC", "WC", "XC", "YC", "CC", "rC"],
    E: "Safari",
    F: {
      hC: 1205798400,
      KC: 1226534400,
      J: 1244419200,
      GB: 1275868800,
      iC: 1311120000,
      K: 1343174400,
      jC: 1382400000,
      E: 1382400000,
      kC: 1410998400,
      F: 1413417600,
      G: 1443657600,
      lC: 1458518400,
      A: 1474329600,
      LC: 1490572800,
      B: 1505779200,
      "7B": 1522281600,
      C: 1537142400,
      "8B": 1553472000,
      L: 1568851200,
      mC: 1585008000,
      M: 1600214400,
      nC: 1619395200,
      H: 1632096000,
      oC: 1635292800,
      MC: 1639353600,
      NC: 1647216000,
      "9B": 1652745600,
      pC: 1658275200,
      AC: 1662940800,
      OC: 1666569600,
      PC: 1670889600,
      QC: 1674432000,
      RC: 1679875200,
      SC: 1684368000,
      qC: 1690156800,
      BC: 1695686400,
      TC: 1698192000,
      UC: 1702252800,
      VC: 1705881600,
      WC: 1709596800,
      XC: 1715558400,
      YC: null,
      CC: null,
      rC: null
    }
  },
  F: {
    A: {
      "0": 0,
      G: 0,
      B: 0,
      C: 0,
      H: 0,
      N: 0,
      O: 0,
      P: 0,
      HB: 0,
      v: 0,
      w: 0,
      x: 0,
      y: 0,
      z: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0.003611,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0.003611,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0,
      cB: 0.018055,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0,
      Q: 0,
      I: 0,
      R: 0,
      GC: 0,
      S: 0.028888,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0.039721,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.028888,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0,
      s: 0.285269,
      t: 0.010833,
      u: 0.310546,
      sC: 0,
      tC: 0,
      uC: 0,
      vC: 0,
      "7B": 0,
      ZC: 0,
      wC: 0,
      "8B": 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "G", "sC", "tC", "uC", "vC", "B", "7B", "ZC", "wC", "C", "8B", "H", "N", "O", "P", "HB", "v", "w", "x", "y", "z", "0", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "Q", "I", "R", "GC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "", "", ""],
    E: "Opera",
    F: {
      "0": 1413331200,
      G: 1150761600,
      sC: 1223424000,
      tC: 1251763200,
      uC: 1267488000,
      vC: 1277942400,
      B: 1292457600,
      "7B": 1302566400,
      ZC: 1309219200,
      wC: 1323129600,
      C: 1323129600,
      "8B": 1352073600,
      H: 1372723200,
      N: 1377561600,
      O: 1381104000,
      P: 1386288000,
      HB: 1390867200,
      v: 1393891200,
      w: 1399334400,
      x: 1401753600,
      y: 1405987200,
      z: 1409616000,
      IB: 1417132800,
      JB: 1422316800,
      KB: 1425945600,
      LB: 1430179200,
      MB: 1433808000,
      NB: 1438646400,
      OB: 1442448000,
      PB: 1445904000,
      QB: 1449100800,
      RB: 1454371200,
      SB: 1457308800,
      TB: 1462320000,
      UB: 1465344000,
      VB: 1470096000,
      WB: 1474329600,
      XB: 1477267200,
      YB: 1481587200,
      ZB: 1486425600,
      aB: 1490054400,
      bB: 1494374400,
      cB: 1498003200,
      dB: 1502236800,
      eB: 1506470400,
      fB: 1510099200,
      gB: 1515024000,
      hB: 1517961600,
      iB: 1521676800,
      jB: 1525910400,
      kB: 1530144000,
      lB: 1534982400,
      mB: 1537833600,
      nB: 1543363200,
      oB: 1548201600,
      pB: 1554768000,
      qB: 1561593600,
      rB: 1566259200,
      sB: 1570406400,
      tB: 1573689600,
      uB: 1578441600,
      vB: 1583971200,
      wB: 1587513600,
      xB: 1592956800,
      yB: 1595894400,
      zB: 1600128000,
      "0B": 1603238400,
      "1B": 1613520000,
      "2B": 1612224000,
      "3B": 1616544000,
      "4B": 1619568000,
      "5B": 1623715200,
      "6B": 1627948800,
      Q: 1631577600,
      I: 1633392000,
      R: 1635984000,
      GC: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200,
      j: 1687219200,
      k: 1690329600,
      l: 1692748800,
      m: 1696204800,
      n: 1699920000,
      o: 1699920000,
      p: 1702944000,
      q: 1707264000,
      r: 1710115200,
      s: 1711497600,
      t: 1716336000,
      u: 1719273600
    },
    D: {
      G: "o",
      B: "o",
      C: "o",
      sC: "o",
      tC: "o",
      uC: "o",
      vC: "o",
      "7B": "o",
      ZC: "o",
      wC: "o",
      "8B": "o"
    }
  },
  G: {
    A: {
      F: 0,
      KC: 0,
      xC: 0,
      aC: 0.00441539,
      yC: 0.0014718,
      zC: 0.00883077,
      "0C": 0.00883077,
      "1C": 0,
      "2C": 0.00735898,
      "3C": 0.0294359,
      "4C": 0.00588718,
      "5C": 0.0515128,
      "6C": 0.135405,
      "7C": 0.014718,
      "8C": 0.0103026,
      "9C": 0.189862,
      AD: 0.00294359,
      BD: 0.0323795,
      CD: 0.0103026,
      DD: 0.0441539,
      ED: 0.13099,
      FD: 0.1148,
      GD: 0.0574,
      MC: 0.0618154,
      NC: 0.0706462,
      "9B": 0.0853641,
      HD: 0.780051,
      AC: 0.15601,
      OC: 0.334098,
      PC: 0.166313,
      QC: 0.279641,
      RC: 0.0662308,
      SC: 0.117744,
      ID: 0.974328,
      BC: 0.0883077,
      TC: 0.142764,
      UC: 0.139821,
      VC: 0.207523,
      WC: 0.518072,
      XC: 9.49014,
      YC: 0.0735898,
      CC: 0.0971385
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "KC", "xC", "aC", "yC", "zC", "0C", "F", "1C", "2C", "3C", "4C", "5C", "6C", "7C", "8C", "9C", "AD", "BD", "CD", "DD", "ED", "FD", "GD", "MC", "NC", "9B", "HD", "AC", "OC", "PC", "QC", "RC", "SC", "ID", "BC", "TC", "UC", "VC", "WC", "XC", "YC", "CC", ""],
    E: "Safari on iOS",
    F: {
      KC: 1270252800,
      xC: 1283904000,
      aC: 1299628800,
      yC: 1331078400,
      zC: 1359331200,
      "0C": 1394409600,
      F: 1410912000,
      "1C": 1413763200,
      "2C": 1442361600,
      "3C": 1458518400,
      "4C": 1473724800,
      "5C": 1490572800,
      "6C": 1505779200,
      "7C": 1522281600,
      "8C": 1537142400,
      "9C": 1553472000,
      AD: 1568851200,
      BD: 1572220800,
      CD: 1580169600,
      DD: 1585008000,
      ED: 1600214400,
      FD: 1619395200,
      GD: 1632096000,
      MC: 1639353600,
      NC: 1647216000,
      "9B": 1652659200,
      HD: 1658275200,
      AC: 1662940800,
      OC: 1666569600,
      PC: 1670889600,
      QC: 1674432000,
      RC: 1679875200,
      SC: 1684368000,
      ID: 1690156800,
      BC: 1694995200,
      TC: 1698192000,
      UC: 1702252800,
      VC: 1705881600,
      WC: 1709596800,
      XC: 1715558400,
      YC: null,
      CC: null
    }
  },
  H: {
    A: {
      JD: 0.05
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "JD", "", "", ""],
    E: "Opera Mini",
    F: {
      JD: 1426464000
    }
  },
  I: {
    A: {
      DC: 0,
      J: 0.0000811276,
      D: 0.808599,
      KD: 0,
      LD: 0.000162255,
      MD: 0,
      ND: 0.000486766,
      aC: 0.00032451,
      OD: 0,
      PD: 0.00129804
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "KD", "LD", "MD", "DC", "J", "ND", "aC", "OD", "PD", "D", "", "", ""],
    E: "Android Browser",
    F: {
      KD: 1256515200,
      LD: 1274313600,
      MD: 1291593600,
      DC: 1298332800,
      J: 1318896000,
      ND: 1341792000,
      aC: 1374624000,
      OD: 1386547200,
      PD: 1401667200,
      D: 1721692800
    }
  },
  J: {
    A: {
      E: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "E", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      E: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      I: 1.119,
      "7B": 0,
      ZC: 0,
      "8B": 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "7B", "ZC", "C", "8B", "I", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      "7B": 1314835200,
      ZC: 1318291200,
      C: 1330300800,
      "8B": 1349740800,
      I: 1709769600
    },
    D: {
      I: "webkit"
    }
  },
  L: {
    A: {
      D: 44.0982
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "", "", ""],
    E: "Chrome for Android",
    F: {
      D: 1721692800
    }
  },
  M: {
    A: {
      D: 0.35134
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "", "", ""],
    E: "Firefox for Android",
    F: {
      D: 1718064000
    }
  },
  N: {
    A: {
      A: 0,
      B: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      "9B": 1.05402
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "9B", "", "", ""],
    E: "UC Browser for Android",
    F: {
      "9B": 1710115200
    },
    D: {
      "9B": "webkit"
    }
  },
  P: {
    A: {
      "0": 1.65553,
      J: 0.0973844,
      v: 0.021641,
      w: 0.043282,
      x: 0.0649229,
      y: 0.0757434,
      z: 0.0757434,
      QD: 0.0108205,
      RD: 0.0108205,
      SD: 0.0324615,
      TD: 0,
      UD: 0,
      LC: 0,
      VD: 0,
      WD: 0,
      XD: 0.0108205,
      YD: 0,
      ZD: 0,
      AC: 0,
      BC: 0.021641,
      CC: 0.0108205,
      aD: 0.021641
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "J", "QD", "RD", "SD", "TD", "UD", "LC", "VD", "WD", "XD", "YD", "ZD", "AC", "BC", "CC", "aD", "v", "w", "x", "y", "z", "0", "", "", ""],
    E: "Samsung Internet",
    F: {
      "0": 1715126400,
      J: 1461024000,
      QD: 1481846400,
      RD: 1509408000,
      SD: 1528329600,
      TD: 1546128000,
      UD: 1554163200,
      LC: 1567900800,
      VD: 1582588800,
      WD: 1593475200,
      XD: 1605657600,
      YD: 1618531200,
      ZD: 1629072000,
      AC: 1640736000,
      BC: 1651708800,
      CC: 1659657600,
      aD: 1667260800,
      v: 1677369600,
      w: 1684454400,
      x: 1689292800,
      y: 1697587200,
      z: 1711497600
    }
  },
  Q: {
    A: {
      bD: 0.338564
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "bD", "", "", ""],
    E: "QQ Browser",
    F: {
      bD: 1710288000
    }
  },
  R: {
    A: {
      cD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "cD", "", "", ""],
    E: "Baidu Browser",
    F: {
      cD: 1710201600
    }
  },
  S: {
    A: {
      dD: 0.070268,
      eD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "dD", "eD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      dD: 1527811200,
      eD: 1631664000
    }
  }
};
export default exports;